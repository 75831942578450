@import "colors.scss";

.ci-search {
    display: table;
    border-collapse: collapse;
    width: 100%;

    border-bottom: 1px solid $default-border;

    > div {
        display: table-row;

        > div {
            display: table-cell;

            input {
                font-size: 22px;
                width: 100%;
                border: none;
                border-right: 1px solid $default-border;
                padding: 6px 8px;
            }

            button {
                font-size: 22px;
                width: 100%;
                border: none;
                background-color: transparent;
            }
        }
    }
}

.ci-content {
    .ci-section-title {
        background-color: $grey;
        color: $text-color-grey;

        font-size: 18px;
        font-weight: bold;
        padding: 4px;
    }

    .ci-section-item {
        padding: 4px;
        margin: 0;
        cursor: pointer;
    }

    .ci-section-item:nth-child(odd) {
        background-color: transparent;
        color: black;
    }

    .ci-section-item:nth-child(even) {
        background-color: $ultra-light;
        color: $ultra-light-text-color;
    }

    .ci-company-entry {
        .ci-company-name {
            font-size: 16px;
        }
    }

    .ci-person-entry {
        .ci-person-name {
            font-size: 16px;
        }

        .ci-person-company {
            padding-left: 24px;
        }
    }
}

.ci-icon {
    margin-right: 5px;
}

.ci-address-block {
    width: 100%;
    background-color: $ultra-light;
    border: 1px solid $default-border;

    .ci-address-info {
        .icon-print {
            vertical-align: middle;
            cursor: pointer;
        }
    }

    .ci-address-block-contact {
        .ci-address-title {
            font-size: 20px;
            font-weight: bold;
            background-color: $light;
        }

        .ci-subtitle {
            font-size: 15px;
        }

        .ci-address-block-detail {
            > * {
                display: block;
            }
            overflow: auto;
            max-height: 300px;
            width: 350px;

            > .ci-notes {
                width: 100%;
                word-break: break-all;
                overflow: hidden;
            }

        }
    }

    .address-link {
        .address-link-info {
            display: block;
        }
    }
}

.ci-contacts {
    .ci-contacts-container {
        display: flex;
        flex-wrap: wrap;
    }

    .ci-contact-info {
        width: 350px;
        border: 1px solid $grey;
        border-top: 10px solid $light;
        background-color: $ultra-light;
        margin: 5px;
        max-height: 200px;
        overflow: auto;

        .ci-contact-name {
            font-size: 15px;
            font-weight: bold;
        }

        .ci-contact-position {
            float: right;
        }

        > .ci-contact-detail {
            margin-top: 10px;
            margin-bottom: 10px;
            border-top: 1px solid grey;

            > * {
                display: block;
            }

            > .ci-float-right  {
                float: right;
            }
        }

        > .ci-contact-notes {
            width: 100%;
            word-break: break-all;
            overflow: hidden;
            border-top: 1px solid grey;
        }
    }
}